@import 'variables';

.queryPlan {
    height: 100%;

    display: grid;
    grid-template-columns: min-content auto min-content;
    grid-template-rows: 100%;

    > .optimizerMenu {
        grid-column: 1;
        grid-row: 1;

        float: left;
        width: 205px;
        position: sticky;
        padding: 0 5px 5px 5px;
        background-color: $sidebar-background;
        border-right: $sidebar-border;
        box-sizing: border-box;
        font-size: small;

        display: flex;
        flex-direction: column;
        justify-items: center;
        justify-content: space-between;
    }

    > .graph {
        grid-column: 2;
        grid-row: 1;
    }

    > .zoomButtons {
        grid-column: 2;
        grid-row: 1;
        align-self: end;
        justify-self: start;

        display: flex;
        flex-direction: row;
        justify-content: space-around;

        margin: 8px;
    }

    > .detailComponentContainer {
        grid-column: 3;
        grid-row: 1;

    }

    .button {
        background-color: $button-secondary-color;
        margin-top: 5px;
        color: white;
        padding-left: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 5px;
        user-select: none;
        height: 28px;

        &:not(.disabled):hover {
            background-color: $button-primary-color;
        }

        &.active {
            background-color: $button-primary-color;
        }

        &.fetchButton, &.zoomButton {
            text-align: center;
        }

        &.zoomButton {
            flex: 1 0 auto;
            margin: 0 4px;
            width: 40px;
            height: 24px;
            padding: 0;
            background-color: $button-primary-color;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }

            &:not(.disabled):hover {
                background-color: $button-secondary-color;
            }
        }
    }
}
