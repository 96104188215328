.ChartEditorLoader {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: rgb(220, 220, 220);

    > div {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 50px;
        height: 50px;
        margin-left: -25px;
        margin-top: -25px;
    }
}
